import { render, staticRenderFns } from "./PageHead.vue?vue&type=template&id=835ac746&scoped=true&"
import script from "./PageHead.vue?vue&type=script&lang=js&"
export * from "./PageHead.vue?vue&type=script&lang=js&"
import style0 from "./PageHead.vue?vue&type=style&index=0&id=835ac746&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "835ac746",
  null
  
)

export default component.exports