<template>
  <div>
    <div class="foot">
      <div class="left">
        <div class="title">CONTACT</div>
        <div class="contact">
          <div class="text">公司：北京水木清芬教育科技有限公司</div>
          <div class="text">电话：010-82827022 18518910225</div>
          <div class="text">邮箱：502368631@qq.com</div>
          <div class="text">地址：清华大学南门文津国际大厦</div>
        </div>
      </div>
      <div class="cen">
        <div class="title">公司旗下产品</div>
        <div class="product">
          <div class="item" v-for="item in product" :key="item.index">
            {{ item }}
          </div>
        </div>
        <div class="copyright">
          Copyright © 2023 北京水木清芬教育科技有限公司
          <a href="https://beian.miit.gov.cn">京ICP备19007391号-2</a>
        </div>
      </div>
      <div class="right">
        <div class="title">微信公众号</div>
        <img class="img" src="../assets/img/business/wxgzh.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      product: [
        "单词彭全脑中心APP",
        "‘触目经心’单词记忆法",
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.foot {
  height: 210px;
  background: #373f5e;
  color: #fff;
  display: flex;
  justify-content: space-around;
  .title {
    text-align: center;
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-top: 28px;
  }
  .left {
    width: 300px;
    height: 210px;
    .contact {
      margin-top: 28px;
      .text {
        color: #8e93a4;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .cen {
    width: 620px;
    height: 210px;
    .product {
      margin-top: 25px;
      height: 38px;
      width: 620px;
      border-bottom: 1px solid #8e93a4;
      display: flex;
      justify-content: space-around;
      .item {
        color: #8e93a4;
        font-size: 14px;
      }
      .item:hover {
        color: #ef8200;
      }
    }
    .copyright {
      margin-top: 28px;
      font-size: 14px;
      color: #8e93a4;
      text-align: center;
    }
  }
  .right {
    width: 120px;
    height: 210px;
    text-align: center;
    .img {
      margin-top: 12px;
      width: 116px;
      height: 116px;
      border-radius: 6px;
    }
  }
}
</style>